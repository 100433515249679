import React from 'react'
import { Link } from 'gatsby'
import { Section, Container, Columns } from 'react-bulma-components'

// Components
import Separator from 'components/separator'
import { StaticImage } from 'gatsby-plugin-image'
import TeamQuote from '../../../../components/team-quote'
import TeamQuotes from '../team'

function OurStory() {
  return (
    <Section>
      <Container>
        <Columns className="is-centered">
          <Columns.Column className="is-6-desktop is-6-tablet is-12-mobile content">
            <h2 className="title">Need to know more?</h2>
            <p>
              We exclusively help organisations create consumer apps that
              positively impact customers, employees, society and shareholders.
            </p>
            <p>
              It's a fine balance; driving new sales, repeat business and
              actually helping people. But it's possible. That's why{' '}
              <strong>
                we bring you the people and processes that have helped our
                clients achieve up to 900% increase in annual sales.
              </strong>
            </p>
            <p>
              Our goal is to help business leaders avoid the frustration of
              blown budgets, late deliveries and apps that don't resonate with
              customers. These are all things that stand in the way of an
              impactful app.
            </p>
            <p>
              We know how hard it is to make a successful app because,{' '}
              <strong>
                in the early days, we worked on plenty that failed
              </strong>
              ; some didn't have the right backing from leadership. Some had
              overly optimistic budgets. And others didn't solve a real customer
              problem.
            </p>
            <p>
              Having the right information and people around you is essential
              when building a winning app. That's why we publish educational
              content and resources to help you learn what we've learned. It's
              also why our people are skilled in{' '}
              <strong>
                user research, user-centric app design, analytics, native mobile
                tech and data-driven growth marketing.
              </strong>
            </p>
            <p>
              These days, we work on apps that we believe{' '}
              <strong>
                have a very good chance of success, with people we respect
              </strong>
              . We embrace risk, because we know that you sometimes have to
              experiment in order to learn. The trick is doing this the lean way
              to save money and time. And if we're in doubt about your app,
              we'll tell you straight.
            </p>
            <h2 className="title">Our story</h2>
            <p>
              Back in 2008, our founder was buzzed when the iPhone launched. For
              the first time, a company had created a mobile platform that put
              the customer experience first.
            </p>

            <p>
              Excited by the potential, Tobin cofounded one of the UK's first
              app development agencies. It grew to 18 people and developed apps
              for well known brands like BNP Paribas and BBraun.
            </p>

            <p>Sadly, that business crashed in 2012.</p>

            <p>
              Despite working with all those big brands, they spent too much
              time on apps that lacked the right ingredients for success.
              Collectively, they didn't have the skills to turn those apps
              around.
            </p>
            <p>
              This failure was hugely frustrating, so we set about increasing
              the odds of success.
            </p>
            <p>
              We started Pocketworks with a mission: To only build apps we
              believe can succeed, with people we respect, and to provide all
              the skills needed to make it happen.
            </p>
            <p>
              All this didn't happen overnight. Over time, we learned the value
              of hearing the customer's voice, embracing data and investing in
              people.
            </p>
            <p>
              Ten years later, Pocketworks still boasts its first client with a
              remarkably successful app, transacting over £100m a year.
            </p>

            <ul>
              <li>£200m+ a year app revenues for our clients</li>
              <li>3m+ yearly app downloads</li>
              <li>7 apps managed - we prioritize quality over quantity</li>
              <li>£0.5m invested in joint ventures</li>
              <li>300+ user research interviews conducted</li>
              <li>10m+ monthly app data points collected</li>
              <li>50+ app updates every year</li>
            </ul>
            <p className="mt-2">
              <Link
                className="button is-medium is-leafy is-primary mt-6"
                to="/contact"
              >
                Get in touch
              </Link>
            </p>
          </Columns.Column>
          <Columns.Column className="is-6 is-vertically-centered">
            <TeamQuotes />
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default OurStory
