import React from 'react'
import { Section, Container, Columns } from 'react-bulma-components'

function Merch() {
  return (
    <Section>
      <Container>
        <Columns className="is-centered">
          <Columns.Column className="is-9-desktop is-9-tablet is-12-mobile content has-text-centered">
            <h2 className="title">
              Looking for <span className="has-text-primary">goodies?</span>
            </h2>
            <div className="box">
              <a
                href="https://pocketworks.shop"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://www.datocms-assets.com/20955/1651737499-pocketworks-merch.png"
                  alt="Pocetworks Merch"
                />
              </a>
            </div>
            <p className="mt-2">
              <a
                href="https://pocketworks.shop"
                className="button is-medium is-leafy is-primary"
                target="_blank"
                rel="noreferrer"
              >
                Buy merch
              </a>
            </p>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default Merch
