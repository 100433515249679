import React from 'react'
import { Link } from 'gatsby'
import { Section, Container, Columns } from 'react-bulma-components'
import { StaticImage } from 'gatsby-plugin-image'

function PartnerWithUs() {
  return (
    <Section className="pt-6 has-background-grey-lighter">
      <Container className="pt-6 mt-6 mb-6 pb-5">
        <Columns className="is-centered">
          <Columns.Column className="is-7-desktop is-6-tablet is-12-mobile content">
            <h2 className="title">Why choose Pocketworks?</h2>
            <p>
              We don't just build apps; we become your supportive digital
              advisors and software delivery team. Work with us to create,
              manage and grow end-to-end digital platforms, whilst having access
              to broad digital skills spanning user research, software
              development, data analysis and growth hacking.
            </p>
            <ul>
              <li>
                <b>Save time:</b> You're busy, so we set things up so we can
                crack on with minimal time investment from your team - typically
                30 mins a week for a medium sized product.
              </li>
              <li>
                <b>Launch a complete product:</b> From initial concept to market
                success, we handle the technologies and delivery processes; no
                need to juggle multiple vendors.
              </li>
              <li>
                <b>Amplify your impact:</b> We specialise in creating apps that
                customers genuinely appreciate and use, directly contributing to
                your market share growth.
              </li>
              <li>
                <b>Sustainability at Core:</b> Our commitment to working with
                impactful, sustainable companies ensures alignment with your
                corporate values and long-term goals.
              </li>
            </ul>
          </Columns.Column>
          <Columns.Column className="is-offset-1 is-4 is-vertically-centered has-text-centered">
            <a
              href="https://www.datocms-assets.com/20955/1619432227-pocket-process.jpg"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://www.datocms-assets.com/20955/1619432227-pocket-process.jpg?compress=auto&w=500"
                alt="Pocketworks Process"
                className="box "
              />
            </a>
            <div className="mt-5">
              <a
                href="https://www.datocms-assets.com/20955/1619432222-pocket-process.pdf"
                className="button is-sm is-leafy"
                target="_blank"
                rel="noreferrer"
              >
                Download Our Process PDF
              </a>
            </div>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default PartnerWithUs
