import React from 'react'

// COmponents
import TeamQuote from 'components/team-quote'

function TeamQuotes() {
  return (
    <TeamQuote
      nobTopBorder
      noBottomBorder
      quote="Ultimately, our gaols is to help
                  organisations create more impact by developing apps and
                  digital platforms that make a tangible difference to people
                  and planet."
      imgSrc="https://www.datocms-assets.com/20955/1579120403-tobin.jpg?fm=png&w=300&fit=facearea&facepad=5&mask=ellipse"
      who="Tobin Harris, Founder and Managing Director"
      className="pt-0 mt-0"
    />
  )
}

export default TeamQuotes
