import React from 'react'
import { Section, Columns } from 'react-bulma-components'

function Instagram() {
  return (
    <Section className="is-paddingless instagram-strip is-hidden">
      <Columns className="is-mobile is-gapless">
        {/* {instagram.edges.map(({ node: instaPost }) => (
            <Columns.Column className="is-1">
              <a
                href={`https://www.instagram.com/p/${instaPost.id}/`}
                target="_blank"
                rel="noreferrer"
              >
                <Img
                  className="instagram-photo"
                  fluid={instaPost.localFile.childImageSharp.fluid}
                  title={instaPost.caption}
                />
              </a>
            </Columns.Column>
          ))} */}
        <Columns.Column className="is-1 mx-auto">
          <center>
            <h1>Instagram Components Goes Here</h1>
          </center>
        </Columns.Column>
      </Columns>
    </Section>
  )
}

export default Instagram
