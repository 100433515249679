import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Section, Container, Hero, Columns } from 'react-bulma-components'

// Components
import Separator from 'components/separator'

function HeroComponent() {
  return (
    <>
      <Hero className="is-small mb-0">
        <Hero.Body>
          <Container className="has-text-centered container content mb-0 pb-0">
            <Columns className="is-centered is-widescreen">
              <Columns.Column className="is-8-widescreen is-12-desktop is-12-mobile">
                <h1 className="title is-size-1-desktop is-size-2-tablet is-size-2-mobile is-top-marginless mt-2 mt-3-mobile is-landing-text">
                  About Pocketworks.
                </h1>
                <p className="is-size-4">
                  Founded in 2012, we're a diverse team of seventeen digital
                  product experts across five European countries, with
                  headquarters in Leeds, UK.
                </p>
              </Columns.Column>
            </Columns>
          </Container>
        </Hero.Body>
      </Hero>
      <Section className="p-0 pb-0 mb-0">
        <Container className="p-0">
          <StaticImage
            src="https://www.datocms-assets.com/20955/1721052407-pocketworks-team-sand.png?fm=jpg&bg=FAF7F2&w=1200"
            className="pb-0"
          />
        </Container>
      </Section>
    </>
  )
}

export default HeroComponent
